import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import type { RouteLocationNormalized } from 'vue-router'; // 👈 Importación de tipo
import { useStore } from '@/store';
import Cookies from 'js-cookie'

// Importación dinámica (lazy loading) de componentes
const HomeView = () => import('@/views/HomeView.vue')
const SimulateCreditView = () => import('../components/simulatecreditview/SimulateCreditView.vue')
const LoginWithOtpView = () => import('../components/loginwithotpview/LoginWithOtpView.vue')
const RequestOtpView = () => import('../components/requestotpview/RequestOtpView.vue')
const Layout = () => import('@/components/Layout.vue')
const RequestCreditView = () => import('@/components/requestcreditview/RequestCreditView.vue')
const DocumentViewerView = () => import('@/components/documentviewerview/DocumentViewerView.vue')
const StoreAikaView = () => import('@/components/StoreAikaView/StoreAikaView.vue')
const CartView = () => import('@/components/CartView/CartView.vue')

// 📌 Componente para el subdominio de Proyectos de Vivienda
const ProyectosViviendaView = () => import('@/components/ProyectosViviendaView/ProyectosViviendaView.vue')

// 🏠 Detectar subdominio
const hostname = window.location.hostname;
const isProyectosVivienda = hostname === "proyectosviviendat.instelmecsas.com";

const routes: Array<RouteRecordRaw> = isProyectosVivienda
? [
    {
      path: '/',
      name: 'ProyectosVivienda',
      component: ProyectosViviendaView,
    },
  ]
: [
    {
      path: '/',
      component: Layout, // Usar Layout como componente principal
      children:[
        {
            path: '',
            name: 'Home', // Tu vista principal (HomeView)
            component: HomeView
        },
        {
          path: '/home',
          name: 'HomeAlias', // Tu vista principal (HomeView)
          component: HomeView
        },
        {
            path: '/auth/send-otp',
            name: 'SendOtp',  // Cambiado a 'SendOtp' para evitar confusión con 'RequestOtp'
            component: RequestOtpView,
            props: (route) => ({
              document: route.query.document ? route.query.document : null, // Maneja el valor del documento de forma segura
            })
        },
        {
            // path: '/auth/login-with-otp/:document',
            path: '/login',
            name: 'LoginWithOtp',
            component: LoginWithOtpView,
            props: true
        },
        {
            path: '/auth/credit-simulator',
            name: 'simulateCredit',
            component: SimulateCreditView,
            props: (route) => ({
              maxAmount: route.query.maxAmount && !Array.isArray(route.query.maxAmount)
                ? parseFloat(route.query.maxAmount)
                : null,
              minAmount: route.query.minAmount && !Array.isArray(route.query.minAmount)
                ? parseFloat(route.query.minAmount)
                :null,
            }),
            meta: { requiresAuth: true} 
        },
        {
            path: '/auth/request-credit', 
            name: 'RequestCredit', 
            component: RequestCreditView,
        },
        {
            path: '/auth/view-documents',
            name: 'DocumentViewer',
            component: DocumentViewerView,
        },
        {
          path: 'auth/validate-otp/',
          name: 'verifyOtp',
          component: DocumentViewerView,
        },
        {
          path: '/store-aika',
          name: 'StoreAika',
          component: StoreAikaView,
        },
        {
          path: '/cart',
          name: 'cartView',
          component: CartView,
        },
        {
          path: '/proyectos-vivienda',
          name: 'ProyectosViviendaAlias',
          component: ProyectosViviendaView,
        },
      ],
    },
  ];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// **Actualizar el título de la pestaña al cambiar de ruta**
router.afterEach((to: RouteLocationNormalized) => {
  const title = to.meta.title as string | undefined;
  document.title = title || 'Aika Cree';
});

router.beforeEach((to, from, next) => {
  const store = useStore();
  const isAuthenticated = store.state.auth.isAuthenticated;

  // Permitir acceso público a /store-aika
  if (to.path === '/store-aika') {
    return next(); // Dejar pasar sin autenticación
  }

  // Verificar rutas que requieren autenticación
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      return next('/'); //Redirigir al login si no está autenticado
    } 
  } 

  // Continuar con la navegación para otras rutas
  return next();
});

// router.beforeEach((to, from, next) => {
//   const store = useStore(); 
  
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!store.state.auth.isAuthenticated) {
//       next('/');
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default router;
